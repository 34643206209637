import logo from "../../assets/ChugWhiteLogoWithRope.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Howdy, Chug fans!</p>
        <font size="2.5">Coming soon, so hold yer horses!</font>
      </header>
    </div>
  ); 
}

export default App;
